import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { navigate } from "gatsby";
import { isEmpty, isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { navigate as clientNavigate } from "@reach/router";

import ClientOnly from "../components/client-only";
import Heading from "../components/heading";
import Layout from "../components/layout";
import Loader from "../components/loader";
import { handleAuthentication } from "../services/auth";

export const GET_USER = gql`
  query GetUser($email: String!) {
    users(where: { email: { _eq: $email } }) {
      id
    }
  }
`;

export const INSERT_USER = gql`
  mutation InsertUser($email: String!) {
    insert_users_one(object: { email: $email }) {
      id
    }
  }
`;

let isMounted = false;

const Callback = () => {
  const [user, setUser] = useState({});
  const [getUser, { data }] = useLazyQuery(GET_USER);
  const [insertUser] = useMutation(INSERT_USER, {
    onCompleted: () => {
      clientNavigate("/app/setup-profile");
    },
  });

  function onAuthenticated({ user: authenticatedUser }) {
    getUser({
      variables: {
        email: authenticatedUser.email,
      },
    });
    setUser(authenticatedUser);
  }

  useEffect(() => {
    if (!isMounted) {
      handleAuthentication(onAuthenticated);
      isMounted = true;
      return null;
    }
  }, [handleAuthentication, onAuthenticated]);

  useEffect(() => {
    if (!isNil(data)) {
      const isExistingUser = !isEmpty(data.users);
      if (isExistingUser) {
        navigate("/app");
      } else {
        insertUser({
          variables: {
            email: user.email,
          },
        });
      }
    }
  }, [data, user?.email]);

  return (
    <ClientOnly>
      <Layout isStatic>
        <>
          <div className="flex flex-col w-1/2 pr-8 transition-all duration-75 delay-75 ease-out">
            <Heading>Get inspired</Heading>
            <Loader />
          </div>
          <div className="flex flex-col w-1/2 pl-8 transition-all duration-75 delay-75 ease-out">
            <Heading>The Latest</Heading>
            <Loader />
          </div>
        </>
      </Layout>
    </ClientOnly>
  );
};

export default Callback;
